import React, { Component } from "react"
import LazyLoad from "react-lazy-load"
import HeroHome from "./Blocks/HeroHome"
import Hero from "./Blocks/Hero"
import TextFullWidth from "./Blocks/TextFullWidth"
import ProductCollectionListing from "./Blocks/ProductCollectionListing"
import ImageLinkBlocks from "./Blocks/ImageLinkBlocks"
import ImageWithText from "./Blocks/ImageWithText"
import CallToActionText from "./Blocks/CallToActionText"
import ContactBlock from "./Blocks/ContactBlock"

class FlexibleContent extends Component {

    render() {
        const acfBlocks = this.props.blocks;
        let blocks = ""
        const lazyOffset = 400

        if (acfBlocks !== null) {
            blocks = acfBlocks.map((acf, i) => {

                console.log(acf.__typename)

                switch (acf.__typename) {
                    case 'WpPage_Acfflexiblecontent_Blocks_HomeHero':
                        return <HeroHome key={i} block={acf} />

                    case 'WpPage_Acfflexiblecontent_Blocks_Hero':
                        return <Hero key={i} block={acf} />

                    case 'WpPage_Acfflexiblecontent_Blocks_TextFullWidth':
                        return <TextFullWidth key={i} block={acf} />

                    case 'WpPage_Acfflexiblecontent_Blocks_CallToActionText':
                        return <CallToActionText key={i} block={acf} />

                    case 'WpPage_Acfflexiblecontent_Blocks_ContactBlock':
                        return (
                            <LazyLoad offset={lazyOffset} key={i}>
                                <ContactBlock block={acf} />
                            </LazyLoad>
                        )

                    case 'WpPage_Acfflexiblecontent_Blocks_ProductCollectionListing':
                        return (
                            <LazyLoad offset={lazyOffset} key={i}>
                                <ProductCollectionListing block={acf} />
                            </LazyLoad>
                        )
                        
                    case 'WpPage_Acfflexiblecontent_Blocks_ImageLinkBlocks':
                        return (
                            <LazyLoad offset={lazyOffset} key={i}>
                                <ImageLinkBlocks block={acf} />
                            </LazyLoad>
                        )

                    case 'WpPage_Acfflexiblecontent_Blocks_ImageWithText':
                        return (
                            <LazyLoad offset={lazyOffset} key={i}>
                                <ImageWithText block={acf} />
                            </LazyLoad>
                        )

                    default:
                        return "";
                }
            })
        }

        return blocks
    }
}

export default FlexibleContent