import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Row } from "reactstrap"
import styled from "styled-components"
import ProductListItem from "components/shared/ProductListItem"
import ContainerMax from "components/shared/ContainerMax"

const CollectionName = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;

    span {
        background-color: ${props => props.theme.colors.white};
        border: 1px solid ${props => props.theme.colors.secondary};
        color: ${props => props.theme.colors.secondary};
        font-weight: ${props => props.theme.font.weight.medium};
        padding: 1rem 2rem;
        position: relative;
        z-index: 10;
    }
    
    &:before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        height: 1px;
        width: 100%;
        background-color: ${props => props.theme.colors.grey4};
    }
`

const ProductCollectionListing = (props) => {
    const collection = props.data.title
    return(
        <ContainerMax className="py-4">
            <CollectionName>
                <span>{collection}</span>
            </CollectionName>
            <Row>
                {props.data.products.map((product, i) => {
                    return(
                        <ProductListItem
                            key={i}
                            product={product}
                        />
                    )
                })}
            </Row>
        </ContainerMax>
    )
}

export default (props) => (
    <StaticQuery
        query={graphql`{
  allShopifyCollection {
    edges {
      node {
        title
        handle
        products {
          handle
          # availableForSale
          title
          priceRangeV2 {
            minVariantPrice {
              amount
            }
            maxVariantPrice {
              amount
            }
          }
          variants {
            shopifyId
            availableForSale
            title
            price
            compareAtPrice
          }
          options {
            shopifyId
            name
            values
          }
          media {
            ... on ShopifyMediaImage {
              id
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 400, placeholder: NONE, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`}
        render={data => {
            const collectionSlug = props.block.productCollection.slug
            const collection = data.allShopifyCollection.edges.filter(c => c.node.handle === collectionSlug)
      
            if (collection) {
                return <ProductCollectionListing data={collection[0].node} />
            } else return ""
        }}
    />
)