import React, { Component } from "react"
import { graphql } from "gatsby"
// import "queries/defaultTemplateQuery"
import Layout from "components/Layout/Layout"
import FlexibleContent from "components/FlexibleContent/FlexibleContent"

class DefaultTemplate extends Component {
    render() {
        const data = this.props.data.wpPage
        const acfBlocks = data.acfFlexibleContent.blocks

        return (
            <Layout 
                path={data.uri} 
                seo={data.seo} 
            >
                <FlexibleContent blocks={acfBlocks} />
            </Layout>
        )
    }
}

export const pageQuery = graphql`query ($id: String!) {
  wpPage(id: {eq: $id}) {
    title
    uri
    seo {
      metaKeywords
      metaDesc
      title
    }
    acfFlexibleContent {
      blocks {
        __typename
        ... on WpPage_Acfflexiblecontent_Blocks_HomeHero {
          title
          subTitle
          subTitleUrl {
            url
            title
            target
          }
          desktopImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
          mobileImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 768, quality: 90, placeholder: NONE, layout: CONSTRAINED)
              }
            }
          }
          promotedLink {
            target
            title
            url
          }
          promotedLinkSubTitle
          promotedLinkImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 185, quality: 90, placeholder: NONE, layout: CONSTRAINED)
              }
            }
          }
        }
        ... on WpPage_Acfflexiblecontent_Blocks_Hero {
          title
          desktopImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 90, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
          mobileImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 768, quality: 90, placeholder: NONE, layout: CONSTRAINED)
              }
            }
          }
        }
        ... on WpPage_Acfflexiblecontent_Blocks_TextFullWidth {
          text
        }
        ... on WpPage_Acfflexiblecontent_Blocks_CallToActionText {
          text
        }
        ... on WpPage_Acfflexiblecontent_Blocks_ContactBlock {
          title
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
              }
            }
          }
          link {
            url
            title
            target
          }
          textBlock1
          textBlock2
        }
        ... on WpPage_Acfflexiblecontent_Blocks_ProductCollectionListing {
          productCollection {
            ... on WpCollection {
              id
              slug
            }
          }
        }
        ... on WpPage_Acfflexiblecontent_Blocks_ImageLinkBlocks {
          blocks {
            title
            link {
              url
              title
              target
            }
            image {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 640, quality: 90, placeholder: NONE, layout: CONSTRAINED)
                }
              }
            }
          }
        }
        ... on WpPage_Acfflexiblecontent_Blocks_ImageWithText {
          title
          text
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 940, quality: 90, placeholder: NONE, layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  }
}
`

export default DefaultTemplate