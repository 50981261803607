import React from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";
import { media } from "utils/Media"
import WPLink from "components/shared/WPLink"
import ContainerMax from "components/shared/ContainerMax"

const Content = styled.div`
    width: calc(100% - 4rem);
    left: 2rem;
    bottom: 2rem;
    position: absolute;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media ${media.md} {
        padding: 1rem;
        left: 1rem;
        bottom: 1rem;
        width: calc(100% - 2rem);
    }

    @media ${media.lg} {
        padding: 2rem;
        left: 2rem;
        bottom: 2rem;
        width: calc(100% - 4rem);
    }

    h3 {
        text-transform: uppercase;
        color: ${props => props.theme.colors.secondary};
        font-weight: ${props => props.theme.font.weight.bold};
        text-align: center;
        width: 100%;
        padding-bottom: 1rem;
        font-size: ${props => props.theme.font.h5.size};

        @media ${media.sm} {
            font-size: ${props => props.theme.font.h3.size};
        }

        @media ${media.md} {
            font-size: ${props => props.theme.font.h5.size};
        }

        @media ${media.lg} {
            font-size: ${props => props.theme.font.h3.size};
        }
    }
`

const ImageLinkBlocks = (props) => {
    const blocks = props.block.blocks.map((block, i) => {
        return (
            <Col className="p-0" key={i} md={4}>
                <GatsbyImage
                    image={block.image.localFile.childImageSharp.gatsbyImageData}
                    alt={block.image.altText} />
                <Content>
                    {block.title && 
                        <h3 dangerouslySetInnerHTML={{ __html: block.title}} />
                    }
                    {block.link &&
                        <WPLink url={block.link.url} target={block.link.target} button color="transparentPrimary">
                            {block.link.title}
                        </WPLink>
                    }
                </Content>
            </Col>
        );
    })

    return (
        <ContainerMax className="py-4" style={{ maxWidth: "1920px"}}>
            <Row>
                {blocks}
            </Row>
        </ContainerMax>
    )
}

export default ImageLinkBlocks