import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image";
import { Row, Col } from "reactstrap"
import styled from "styled-components"
import ContainerMax from "components/shared/ContainerMax"

const Title = styled.h2`
    color: ${props => props.theme.colors.secondary};
`

const ColStyled = styled(Col)`
    color: black;
    
    h1, h2, h3, h4 {
        color: ${props => props.theme.colors.primary};
        font-weight: ${props => props.theme.font.weight.bold};
    }

    a {
        color: black;
    }
`
 
const ContactBlock = (props) => {
    const { title, image, link, textBlock1, textBlock2 } = props.block

    return (
        <ContainerMax className="py-4">
            {title && 
                <Title className="pb-3">{title}</Title>
            }
            {image && link && 
                <a href={link.url} target="_blank" rel="noopener noreferrer">
                    <GatsbyImage
                        image={image.localFile.childImageSharp.gatsbyImageData}
                        alt={image.altText}
                        className="my-4" />
                </a>
            }
            <Row className="py-3">
                {textBlock1 && 
                    <ColStyled xs={12} md={6} lg={4} className="pb-3">
                        <div dangerouslySetInnerHTML={{ __html: textBlock1 }} />
                    </ColStyled>
                }
                {textBlock2 && 
                    <ColStyled xs={12} md={6} lg={4}>
                        <div dangerouslySetInnerHTML={{ __html: textBlock2 }} />
                    </ColStyled>
                }
            </Row>
        </ContainerMax>
    );
}
 
export default ContactBlock;