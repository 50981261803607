const FilterUrls = (text) => {
    if (text) {
        const urls = [
            "https://cms-staging.bowwowlondon.org",
            "http://cms-staging.bowwowlondon.org",
            "https://cms.bowwowlondon.org",
            "http://cms.bowwowlondon.org"
        ]
    
        let newText = text
    
        urls.forEach((url) => {
            newText = newText.replace(url, "")
        })
    
        return newText
    }
} 

export { FilterUrls }