import React from "react"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import { media } from "utils/Media"
import { FilterUrls } from "utils/FilterUrls"

const Text = styled.div`
    font-size: ${props => props.theme.font.size.md};

    @media ${media.lg} {
        font-size: ${props => props.theme.font.size.lg};
    }
`

const TextBlockFullWidth = (props) => {
    let { text } = props.block

    return (
        <Container className="py-4">
            <Row>
                <Col>
                    {text && 
                        <Text dangerouslySetInnerHTML={{ __html: FilterUrls(text) }} />
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default TextBlockFullWidth