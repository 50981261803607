import React from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";
import { media } from "utils/Media"
import ContainerMax from "components/shared/ContainerMax"

const Title = styled.h3`
    color: ${props => props.theme.colors.secondary};
    padding-top: 1rem;

    @media ${media.md} {
        font-size: ${props => props.theme.font.h2.size};
    }

    @media ${media.xl} {
        padding-top: 0;
        font-size: ${props => props.theme.font.h1.size};
    }
`

const Text = styled.div`
    @media ${media.md} {
        font-size: ${props => props.theme.font.size.lg};
    }
`

const ImageLinkBlocks = (props) => {
    const { image, title, text } = props.block

    return (
        <ContainerMax className="py-4">
            <Row className="align-items-center">
                <Col lg={6} xl={7}>
                    {image &&
                        <GatsbyImage
                            image={image.localFile.childImageSharp.gatsbyImageData}
                            alt={image.localFile.childImageSharp.altText} />
                    }
                </Col>
                <Col lg={6} xl={5}>
                    {title &&
                        <Title>{title}</Title>
                    }
                    {text &&
                        <Text dangerouslySetInnerHTML={{ __html: text }} />
                    }
                </Col>
            </Row>
        </ContainerMax>
    );
}

export default ImageLinkBlocks