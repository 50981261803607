import React, { Component } from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components"
import { Row, Col } from "reactstrap"
import { media } from "utils/Media"
import ContainerMax from "components/shared/ContainerMax"
import WPLink from "components/shared/WPLink"
import Arrow from "images/Arrow.svg"

const ImgStyled = styled(GatsbyImage)`
    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .3);
    }
`

const ContainerMaxStyled = styled(ContainerMax)`
    position: relative;
    @media ${media.md} {
        text-align: left;
        color: ${props => props.theme.colors.white};
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        z-index: 10;
    }
`

const Title = styled.h1`
    color: ${props => props.theme.colors.primary};
    font-weight: ${props => props.theme.font.weight.medium};
    padding-bottom: 1rem;
    font-size: 1.5rem;
    position: relative;
    z-index: 2;
    margin: 0;
    text-align: center;

    @media ${media.md} {
        padding: 0;
        text-align: left;
        color: ${props => props.theme.colors.white};
        font-size: 2.5rem;
        font-weight: ${props => props.theme.font.weight.black};
    }

    @media ${media.lg} {
        font-size: 3rem;
    }

    @media ${media.xl} {
        font-size: 4rem;
    }
`

const SubTitle = styled.h2`
    color: ${props => props.theme.colors.secondary};
    position: relative;
    z-index: 2;
    padding: 1rem 0;
    margin: 0;
    text-align: center;
    font-weight: ${props => props.theme.font.weight.regular};
    display: none;

    @media ${media.md} {
        display: block;
    }

    @media ${media.md} {
        text-align: left;
        color: ${props => props.theme.colors.white};
        font-size: 1.5rem;
    }

    @media ${media.lg} {
        font-size: 2rem;
    }

    @media ${media.xl} {
        font-size: 3rem;
    }
`

const PromotedLink = styled(WPLink)`
    background-color: white;
    padding: 1rem;
    padding-bottom: 0;
    position: absolute;
    bottom: 0;
    width: calc(100% - 30px);
    display: none;
    font-size: ${props => props.theme.font.size.lg};
    color: ${props => props.theme.colors.black};

    @media ${media.md} {
        display: flex;
        padding: 0 1rem;
        padding-bottom: 0;
    }

    @media ${media.lg} {
        padding: 1rem;
        padding-bottom: 0;
    }

    &:hover {
        text-decoration: none;
        
        .arrow {
            position: relative;
            animation-name: bounce;
            animation-duration: .4s;
            animation-fill-mode: forwards;
        }
    }

    div:last-child {
        padding: 1rem 0;

        .title {
            font-weight: ${props => props.theme.font.weight.black};
            display: block;
            padding-bottom: 1rem;
        }

        .subtitle {
            font-weight: ${props => props.theme.font.weight.regular};
            font-size: ${props => props.theme.font.size.sm};
            display: block;
            text-transform: uppercase;
        }
    }

    .gatsby-image-wrapper { 
        width: 50%;
        margin-right: 5%;

        @media ${media.xl} {
            width: 40%;
        }
    }

    @keyframes bounce {
        0%   { 
            left: 0; 
        }
        100%  { 
            left: calc(100% - 36px); 
        }
    }
`

class HeroHome extends Component {

    render() {
        const { 
            desktopImage, 
            mobileImage, 
            title, 
            subTitle,
            subTitleUrl,
            promotedLink,
            promotedLinkSubTitle,
            promotedLinkImage

        } = this.props.block
        
        return (
            <ContainerMax fluid className="px-0 position-relative" style={{ maxWidth: "1920px"}}>     
                <ContainerMaxStyled>
                    <Row>
                        <Col md={8} xl={9} className="pb-3">
                            {title &&
                                <Title dangerouslySetInnerHTML={{ __html: title }} />
                            }
                            {subTitle &&
                                <>
                                    {subTitleUrl ? (
                                        <WPLink url={subTitleUrl.url} target={subTitleUrl.target}>
                                            <SubTitle>{subTitle}</SubTitle>
                                        </WPLink>
                                    ) : (
                                        <SubTitle>{subTitle}</SubTitle>
                                    )}
                                </>
                            }
                        </Col>
                        {promotedLink && 
                            <Col md={4} xl={3}>
                                <PromotedLink url={promotedLink.url} target={promotedLink.target}>
                                    <GatsbyImage
                                        image={promotedLinkImage.localFile.childImageSharp.gatsbyImageData}
                                        alt={promotedLinkImage.altText}
                                        className="d-none d-lg-block" />
                                    <div>
                                        <span className="title">{promotedLink.title}</span>
                                        {promotedLinkSubTitle && 
                                            <span className="subtitle">{promotedLinkSubTitle}</span>
                                        }
                                        <img src={Arrow} alt="" width="36" className="pt-3 arrow" />
                                    </div>
                                </PromotedLink>
                            </Col>
                        }
                    </Row>
                </ContainerMaxStyled>       
                <ImgStyled image={mobileImage.localFile.childImageSharp.gatsbyImageData} alt={this.props.block.desktopImage.altText} className="d-md-none"/>
                <ImgStyled image={desktopImage.localFile.childImageSharp.gatsbyImageData} alt={this.props.block.desktopImage.altText} className="d-none d-md-block" />
            </ContainerMax>
        );
    }
}

export default HeroHome