import React, { Component } from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components"
import { media } from "utils/Media"
import ContainerMax from "components/shared/ContainerMax"

const ImgStyled = styled(GatsbyImage)`
    &:before {
        content: "";
        position: absolute;
        z-index: 1;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, .3);
    }
`

const Title = styled.h1`
    color: ${props => props.theme.colors.secondary};
    position: relative;
    z-index: 2;
    padding: 1rem 0;
    margin: 0;
    text-align: center;

    @media ${media.md} {
        text-align: left;
        color: ${props => props.theme.colors.white};
        position: absolute;
        bottom: 1rem;
        font-size: 3.8rem;
    }
`

class Hero extends Component {

    render() {
        const { desktopImage, mobileImage, title } = this.props.block
        
        return (
            <ContainerMax fluid className="px-0 position-relative" style={{ maxWidth: "1920px"}}>            
                <ImgStyled image={mobileImage.localFile.childImageSharp.gatsbyImageData} alt={this.props.block.desktopImage.altText} className="d-md-none"/>
                <ImgStyled image={desktopImage.localFile.childImageSharp.gatsbyImageData} alt={this.props.block.desktopImage.altText} className="d-none d-md-block" />
                {title && 
                    <ContainerMax>
                        <Title>{title}</Title>
                    </ContainerMax>
                }
            </ContainerMax>
        )
    }
}

export default Hero