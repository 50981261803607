import React from "react"
import { Row, Col } from "reactstrap"
import styled from "styled-components"
import { media } from "utils/Media"
import { FilterUrls } from "utils/FilterUrls"
import ContainerMax from "components/shared/ContainerMax"

const ContainerStyled = styled(ContainerMax)`
    background-color: ${props => props.theme.colors.navy};
`

const Text = styled.div`
    font-size: ${props => props.theme.font.size.md};
    color: ${props => props.theme.colors.white};

    a {
        color: ${props => props.theme.colors.white};
    }

    @media ${media.lg} {
        font-size: ${props => props.theme.font.size.xl};
    }
`

const CallToActionText = (props) => {
    let { text } = props.block

    return (
        <ContainerStyled fluid className="my-4 py-5">
            <ContainerMax>
                <Row>
                    <Col>
                        {text && 
                            <Text dangerouslySetInnerHTML={{ __html: FilterUrls(text) }} />
                        }
                    </Col>
                </Row>
            </ContainerMax>
        </ContainerStyled>
    )
}

export default CallToActionText